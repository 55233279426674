
import './coming.scss';

const Coming = () => {
  return (
    <>
      Coming Soon
    </>
  );
};

export default Coming;